<template >

    <section  class="list">
           <loading v-if="isLoading"></loading>
    <div class="container">
            <div>
            <hr>
        </div>
            <div class="search bar">
                <div class="form">
                <input type="text" v-model="keywords" placeholder="请输入搜索内容" @keyup.enter="StartSearch">
                <button @click="StartSearch" ></button>
                </div>
            </div>
      
    
    <div>
        <div v-if="Listdata.isSuccess == false">
            <hr>
                <div class="tipContentTips">
                <p class="help">暂无搜索结果，请输入搜索内容，</p>
                <p class="help">或尝试输入其他关键词。</p>
                <p class="help">感谢使用！</p>
    
            </div>
            <hr>
        </div>
        <div class="tit" v-if="Listdata.isSuccess">与{{keywords}}相关的搜索结果 
            <span></span>
            <hr>
    
        </div> 
    
        <div class="items" v-if="Listdata.isSuccess">
    
    
            <figure  v-for="p in Listdata.info" :key="p.id">
                <div class="pos">
                    <router-link :to="{
                        name:'M5Detail',
                        params:{
                            cid:p.classid,
                            id:p.id,
                          
    
                            
                        }
                    }"  :title="p.title">
                        <img  :src="p.titlepic" :key="p.id"/>
                        <p><span>{{p.newstime}}</span></p>
    
                    </router-link>
                </div>
                <router-link :to="{
                        name:'M5Detail',
                        params:{
                            cid:p.classid,
                            id:p.id,
                        
                          
                        }
                    }"  :title="p.title">
                    <figcaption>
                        {{p.title}}
                    </figcaption>
                </router-link>
            </figure>
    
        </div>
    
     <div class="pageDiv" v-if="Listdata.isSuccess == true">
       <ul class="paginationU">
              <li class="page-item" ><router-link class="page-link" :to="{
                        name:'M5Search',
                         params:{
                            kw:keywords,
                            page:prePage
                        }
                    }">◀</router-link></li>
            <li class="page-item" :class="{active: curPage == Number(number)+1 }"  v-for="(number,index) of ListItem" :key="index">
                <router-link class="page-link" :to="{
                        name:'M5Search',
                         params:{
                            kw:keywords,
                            page:Number(number)+1
                        }
                    }">{{Number(number)+1}}</router-link></li>
    
             <li class="page-item"><router-link class="page-link" :to="{
                        name:'M5Search',
           
                        params:{
                            kw:keywords,
                            page:nextPage
                        }
                    }">▶</router-link></li>
           
        </ul>
        </div>
    </div>
    </div>
    </section>
    </template>
    <script>
    
    import apis from "@/utils/crypt"
    import loading from "../components/M5Loading"
    export default {
        name:"M5Search",
        components:{loading},
       
        data(){
            return {
                Listdata:{
                    isSuccess:false,
                    info:"",
                    msg:"",
                    
                },
         
                isLoading:false,
                curPage: "1",
                total:0,
                pageCount:0,//显示的内容
                SearchFlag:false,
                routeInfo:{
                    skw:"",
                    spage:"",
                },
                keywords:"",
                pageList:[],
     
               
            }
        },
    
        computed:{
    
    
            
    
    
            prePage(){
                if(this.curPage != 1){
                     return this.curPage - 1;
                }else{
                    return 1
                }
            },
            
           
            
            nextPage(){
                if(this.curPage != this.pageCount){
                    return this.curPage + 1;
                }else{
                    return this.pageCount
                }
            },
              ListItem(){
          
                let start
                let end
                if (this.curPage < 6) {
                    start = 0
                    end = 7
                }else if (this.curPage == this.pageCount){
                    start = this.curPage - 7
                    end = this.curPage
                }else{
                    start = this.curPage - 3
                    end = this.curPage + 4
                }
                
               
    
                return this.pageList.slice(start,end)
            },
        },
     
    
        methods:{
           getListInfo(keywords,page){
                    this.curPage = "1"
                    this.isLoading = true
                    this.$ajax.post(this.Url.SearchAddr,{
                    k:apis.rsaEncode(keywords),
                    p:apis.rsaEncode(page.toString()),
                 
                }).then(
                     response => {
                   
                    
                        this.curPage = response.data.PageNo
                        this.total = response.data.TotalRecord
                        this.pageCount = response.data.TotalPageNo    

                        this.Listdata.info = response.data
                      
                        if (this.total == 0) {
                            this.Listdata.isSuccess = false
                        }else{
                            this.Listdata.isSuccess = true
                        }
      
              
            
                       
                       
                        document.title = this.keywords
                         this.pageList =  Object.keys(Array.from(Array(this.pageCount)))
                        this.isLoading = false
                  
        
                
                })
               
            },
           
            StartSearch(){
        
                if(this.keywords == null){
                    window.location.reload()
                }else{
                     let kw = this.keywords.trim()
                     if (this.curPage == undefined) {
                        this.curPage = "1"
                     }
                    this.getListInfo(kw,this.curPage) 
                }
                
            },
           
        },
        mounted(){
            
         
    
                
                
           
        },
         beforeRouteUpdate(to,from,next){
           
            if (this.keywords == ""){
                this.isLoading = false
                next()
            }else{
              let kw = this.keywords.trim()
              this.getListInfo(kw,to.params.page)
              next()
            }
       
        },
    
    
        
    }
    </script>
    <style scoped>
      * {
    
        box-sizing:border-box;
    
    }
    .items>span {
        position:relative;
    
        width:50%;
    
        margin:0 auto;
        font-size:12px;
    }
    div.search {
    
        padding:10px 0;
    
    }
    
    .form {
    
        position:relative;
    
        width:50%;
    
        margin:0 auto;
    
    }
    
    input,button {
    
        border:none;
    
        outline:none;
    
    }
    
    input {
    
        width:100%;
    
        height:42px;
    
        padding-left:13px;
        color:rgb(216, 47, 47);
    
    }
    
    button {
    
        height:42px;
    
        width:42px;
    
        cursor:pointer;
    
        position:absolute;
    
    }    
    
    .bar input {
    
        border:2px solid #d8d2d2;
    
        border-radius:5px;
    
        background:transparent;
    
        top:0;
    
        right:0;
    
    }
    
    .bar button {
    
        background:#c5464a;
    
        border-radius:0 5px 5px 0;
    
        width:60px;
    
        top:0;
    
        right:0;
    
    }
    
    .bar button:before {
    
        content:"搜索";
    
        font-size:13px;
    
        color:#F9F0DA;
    
    }
    @media only screen and (max-width: 900px) {
        .form {
    
    
    
        width:80%;
    
        
    
    }
        .tipContentTips {
            margin-top: 20px;
        }
    }
    </style>